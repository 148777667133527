import { gql } from "@apollo/client";

export default gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      user {
        _id
        firstName
        lastName
        operatorContracts
      }
    }
  }
`;
