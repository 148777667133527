import { gql } from "@apollo/client";

export default gql`
  query i18n {
    i18n {
      _id
      languages
      key
      documents {
        _id
        translations
        lang
        createdAt
      }
    }
  }
`;
