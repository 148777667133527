import React from "react";
import PropTypes from "prop-types";

import useDictionaries from "assets/commonHooks/useDictionaries";
import MDTypography from "assets/@ndres-ui/MDTypography";

const ContainerInGrid = (props) => {
  const { headerName, headerTextColor, headerBackgroundColor, children, ...rest } = props;
  const text = useDictionaries();
  const translatedHeaderName = text(headerName);
  return (
    <>
      <div
        style={{
          background: headerBackgroundColor,
          width: "100%",
          height: "45px",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          display: "flex",
          alignItems: "center",
          zIndex: "100",
          position: "fixed",
          backgroundColor: "#800080",
        }}
      >
        <MDTypography type="widgetHeader" style={{ paddingLeft: "15px", fontSize: "20px" }} color={"white"}>
          {translatedHeaderName}
        </MDTypography>
      </div>
      <main style={{ height: "90%", marginTop: "55px" }}>{children}</main>
    </>
  );
};

ContainerInGrid.propTypes = {
  headerTextColor: PropTypes.string.isRequired,
  headerBackgroundColor: PropTypes.string.isRequired,
  headerName: PropTypes.string.isRequired,
};

export default ContainerInGrid;
