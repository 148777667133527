/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { subDays, format, addDays } from "date-fns";
import DatePicker from "rsuite/DatePicker";
import DateRangePicker from "rsuite/DateRangePicker";
import { Modal, Switch } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";

import useDictionaries from "assets/commonHooks/useDictionaries";

import InputHidable from "assets/@luc-ui/InputHidable";
import MDTypography from "assets/@ndres-ui/MDTypography";
import MDBox from "assets/@ndres-ui/MDBox";
import MDButton from "assets/@ndres-ui/MDButton";
import { isToday } from "../../../commonFunctions/isToday";

const initForm = {
  startDate: new Date(),
  stopDate: new Date(),
  spacesAllocated: 0,
  name1: "",
  name2: "",
  city: "",
  zipcode: "",
  country: "",
  address1: "",
  address2: "",
};

const BusinessTenantsTable = ({
  tenant,
  contract,
  title,
  isParent,
  allowEditing,
  onContractUpdate,
  allowEditingSpaces,
}) => {
  const { allowedRange, beforeToday, combine } = DateRangePicker;
  const text = useDictionaries();

  const { name1, name2, city, zipcode, country, address1, address2 } = tenant;
  const memoContract = useMemo(() => contract, [contract]);
  const {
    startDate,
    stopDate,
    spacesAllocated,
    spacesOccupied,
    product,
    isEnabled,
    contractStatus,
    ospContractId,
    _id,
    name1: contractName,
  } = memoContract;

  const [formData, setFormData] = useState({
    ...initForm,
  });
  const [errors, setErrors] = useState(false);
  const [isEditable, setEditable] = useState(false);

  useEffect(() => {
    if (isParent) {
      setFormData({
        startDate: startDate ?? new Date(),
        stopDate: stopDate ?? new Date(),
        spacesAllocated: spacesAllocated ?? 0,
        spacesOccupied: spacesOccupied ?? null,
        name1: name1 ?? "",
        name2: name2 ?? "",
        city: city ?? "",
        zipcode: zipcode ?? "",
        country: country ?? "",
        address1: address1 ?? "",
        address2: address2 ?? "",
      });
    }
    if (!isParent) {
      setFormData({
        name1: contractName,
        startDate: startDate ?? new Date(),
        stopDate: stopDate ?? new Date(),
        spacesAllocated: spacesAllocated ?? 0,
        spacesOccupied: spacesOccupied ?? null,
      });
    }
  }, [contract]);

  useEffect(() => {
    if (isParent) {
      if (formData.name1.length < 1) {
        setErrors(true);
      }
      if (formData.name1.length > 0) {
        setErrors(false);
      }
    }
  }, [formData.name1]);

  const handleEditClick = () => {
    setEditable(!isEditable);
  };
  const handleSaveClick = () => {
    if (!errors) {
      setEditable(!isEditable);
      onContractUpdate({ contractId: _id, input: formData });
    }
  };

  const colorSelector = {
    "motorist-contract-status-active": "success",
    "motorist-contract-status-suspended": "warning",
    "motorist-contract-status-expired": "dark",
  };

  if (!contract || !product?.name) {
    return "loading";
  }

  return (
    <>
      <section className="section_details">
        <MDTypography className="global_xs_title" type="tableHeader" textTransform="first">
          {title}
        </MDTypography>

        <div className="global_separa"></div>
        <div className="place_rub">
          <div className="flexTableRows">
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenant-details-table-contract-id")}
              </MDTypography>
            </div>
            <div className="flexTableData">
              <MDTypography component="span" type="tableData">
                {ospContractId}
              </MDTypography>
            </div>
            <div className="flexTableHead">
              {isParent ? (
                <MDTypography className="global_label" type="tableData" textTransform="first">
                  {text("widgets-business-tenant-details-table-contract-product")}
                </MDTypography>
              ) : (
                <MDTypography className="global_label" type="tableData" textTransform="first">
                  {text("widgets-business-tenant-details-table-contract")}
                </MDTypography>
              )}
            </div>
            <div className="flexTableData">
              {isParent ? (
                <MDTypography type="tableData" textTransform="first">
                  {text(`product-${product?._id}-name`)}
                </MDTypography>
              ) : (
                <MDTypography component="span" type="tableData">
                  <InputHidable
                    data={formData.name1}
                    type="text"
                    isEditable={isEditable}
                    onChange={(data) => setFormData({ ...formData, name1: data })}
                    width="100%"
                  />
                </MDTypography>
              )}
            </div>
          </div>
          <div className="flexTableRows">
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenant-details-table-contract-startDate")}
              </MDTypography>
            </div>
            <div className="flexTableData">
              {!isEditable ? (
                <MDTypography type="tableData">{DateTime.fromISO(startDate).toFormat("dd.LL.yy HH:mm")} </MDTypography>
              ) : (
                <DatePicker
                  value={new Date(formData?.startDate)}
                  format="dd.LL.yy HH:mm"
                  disabled={DateTime.fromISO(startDate) < DateTime.now() ? true : false}
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      startDate: isToday(date)
                        ? new Date()
                        : new Date(DateTime.fromISO(date.toISOString()).startOf("day")),
                      stopDate: null,
                    });
                  }}
                  ranges={[]}
                  style={{
                    width: "90%",
                  }}
                  oneTap
                  cleanable={false}
                  shouldDisableDate={allowedRange(DateTime.now(), subDays(new Date(product?.stopDate), 1))}
                />
              )}
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenant-details-table-contract-endDate")}
              </MDTypography>
            </div>
            <div className="flexTableData">
              {!isEditable ? (
                <MDTypography type="tableData">{DateTime.fromISO(stopDate).toFormat("dd.LL.yy HH:mm")}</MDTypography>
              ) : (
                <DatePicker
                  style={{
                    width: "90%",
                  }}
                  value={new Date(formData?.stopDate)}
                  format="dd.LL.yy HH:mm"
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      stopDate: new Date(DateTime.fromISO(date.toISOString()).endOf("day")),
                    });
                  }}
                  ranges={[]}
                  oneTap
                  cleanable={false}
                  shouldDisableDate={combine(
                    allowedRange(
                      format(
                        formData.startDate ? addDays(new Date(formData.startDate), 1) : new Date(product.startDate),
                        "yyyy-MM-dd",
                      ),
                      format(subDays(new Date("2099-12-31"), 1), "yyyy-MM-dd"),
                    ),
                    beforeToday(),
                  )}
                />
              )}
            </div>
          </div>
          <div style={{ marginTop: `${isEditable ? "-7px" : "0px"}` }} className="flexTableRows">
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenant-details-table-contract-no-of-spaces")}
              </MDTypography>
            </div>
            <div className="flexTableData">
              <MDTypography component="span" type="tableData">
                <InputHidable
                  data={formData.spacesOccupied}
                  type="number"
                  isEditable={allowEditingSpaces && isEditable}
                  onChange={(space) =>
                    setFormData({
                      ...formData,
                      spacesOccupied: Math.max(0, Math.min(9999, Number(parseInt(space)))),
                    })
                  }
                  width="30%"
                />{" "}
                /{" "}
                <InputHidable
                  data={formData.spacesAllocated}
                  type="number"
                  isEditable={isEditable}
                  onChange={(space) =>
                    setFormData({
                      ...formData,
                      spacesAllocated: Math.max(0, Math.min(9999, Number(parseInt(space)))),
                    })
                  }
                  width="30%"
                />
              </MDTypography>
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenant-details-table-partner-status")}
              </MDTypography>
            </div>
            <div className="flexTableData">
              <MDTypography
                type="status"
                style={{ transform: `${isEditable ? "translateX(-5px)" : ""}` }}
                color={colorSelector[contractStatus]}
                textTransform="first"
              >
                {allowEditing ? (
                  <span>
                    {isEditable ? (
                      <Switch
                        size="medium"
                        color="warning"
                        checked={isEnabled}
                        onChange={(event) =>
                          onContractUpdate({
                            input: { isEnabled: event.target.checked },
                            contractId: _id,
                          })
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </span>
                ) : (
                  <></>
                )}
                {text(contractStatus)}
              </MDTypography>
            </div>
          </div>

          <div style={{ marginTop: `${isEditable ? "-5px" : "0px"}` }} className="flexTableRows">
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenant-details-table-partner-name1")}
              </MDTypography>
            </div>
            <div className="flexTableData">
              <MDTypography component="span" type="tableData">
                <InputHidable
                  data={formData.name1}
                  type="text"
                  isEditable={isEditable}
                  onChange={(data) => setFormData({ ...formData, name1: data })}
                  width="90%"
                />
              </MDTypography>
              {errors ? (
                <MDTypography type="status" color="error">
                  {text("widgets-business-tenant-details-error-name-required")}
                </MDTypography>
              ) : (
                <></>
              )}
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenant-details-table-partner-name2")}
              </MDTypography>
            </div>
            <div className="flexTableData">
              <MDTypography component="span" type="tableData">
                <InputHidable
                  data={formData.name2}
                  type="text"
                  isEditable={isEditable}
                  onChange={(data) =>
                    setFormData({
                      ...formData,
                      name2: data ? data : "",
                    })
                  }
                  width="90%"
                />
              </MDTypography>
            </div>
          </div>
          <div className="flexTableRows">
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenant-details-table-partner-address1")}
              </MDTypography>
            </div>
            <div className="flexTableData">
              <MDTypography component="span" type="tableData">
                <InputHidable
                  data={formData.address1}
                  type="text"
                  isEditable={isEditable}
                  onChange={(data) =>
                    setFormData({
                      ...formData,
                      address1: data ? data : "",
                    })
                  }
                  width="90%"
                />
              </MDTypography>
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenant-details-table-partner-address2")}
              </MDTypography>
            </div>
            <div className="flexTableData">
              <MDTypography component="span" type="tableData">
                <InputHidable
                  data={formData.address2}
                  type="text"
                  isEditable={isEditable}
                  onChange={(data) =>
                    setFormData({
                      ...formData,
                      address2: data ? data : "",
                    })
                  }
                  width="90%"
                />
              </MDTypography>
            </div>
          </div>
          <div className="flexTableRows">
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenant-details-table-partner-zip-city")}
              </MDTypography>
            </div>
            <div className="flexTableData">
              <MDTypography component="span" type="tableData" textTransform="capitalize">
                <MDTypography component="span" type="tableData">
                  <InputHidable
                    data={formData.zipcode}
                    type="text"
                    isEditable={isEditable}
                    onChange={(data) =>
                      setFormData({
                        ...formData,
                        zipcode: data ? data : "",
                      })
                    }
                    width="40%"
                  />
                </MDTypography>{" "}
                /{" "}
                <MDTypography component="span" type="tableData">
                  <InputHidable
                    data={formData.city}
                    type="text"
                    isEditable={isEditable}
                    onChange={(data) =>
                      setFormData({
                        ...formData,
                        city: data ? data : "",
                      })
                    }
                    width="40%"
                  />
                </MDTypography>
              </MDTypography>
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenant-details-table-partner-country")}
              </MDTypography>
            </div>
            <div className="flexTableData">
              <MDTypography component="span" type="tableData">
                <InputHidable
                  data={formData.country}
                  type="text"
                  isEditable={isEditable}
                  onChange={(data) =>
                    setFormData({
                      ...formData,
                      country: data ? data : "",
                    })
                  }
                  width="40%"
                />
              </MDTypography>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "15px",
            }}
          >
            {allowEditing && !isParent ? (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isEditable ? (
                    <MDButton disabled={errors} onClick={handleSaveClick} color="primary">
                      {text("wizard-button-save")}
                    </MDButton>
                  ) : (
                    <MDButton onClick={handleEditClick} color="primary">
                      {text("wizard-button-edit")}
                    </MDButton>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      <style jsx="true">{`
        .rs-picker-toggle {
          height: auto;
          padding: 5px;
          width: 100%;
        }
        .rs-input {
          height: auto;
          padding: 2px;
        }
      `}</style>
    </>
  );
};

export default BusinessTenantsTable;
