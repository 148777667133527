import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";
import { service } from "../reducer";
import { service as sessionsService } from "../../Sessions/reducer";
import { DateTime } from "luxon";
const defaultContextData = {
  data: {
    motoristInfos: {},
  },
};

const findLast = (array, filter) => {
  const result = array.filter(filter);
  return result[result.length - 1];
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  const currentSession = useSelector(service, ({ context }) => context.session);

  const selectedSession = useSelector(sessionsService, ({ context }) => context.selectedSession);

  useEffect(() => {
    if (!selectedSession) return;

    dispatch({
      type: `${storeName}:show`,
      payload: { sessionId: selectedSession },
    });
  }, [selectedSession]);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(() =>
    Object.entries(events).reduce(
      (acc, [key, fn]) => ({
        [key]: (src) => eventDispatch(key, fn, src),
        ...acc,
      }),
      {},
    ),
  );

  const renderSession = useMemo(() => {
    if (!currentSession) return;

    const motoristInfos = {
      ...(currentSession?.motorist || {}),
      motoristId: currentSession?.motorist?._id,
      isAnonymous: !currentSession?.motorist,
      email: currentSession?.motorist?.username,
      address: currentSession?.motorist?.address1,
      addressDetails: currentSession?.motorist?.address2,
      state: currentSession?.motorist?.state,
    };
    const { _id: parkingId, accessPoints } = currentSession?.parking;
    const { _id: sessionId } = currentSession;

    const { _id: contractId, isEnabled, stopDate, startDate, product, ospContractId } = currentSession.contract || {};
    let contractStatus = "";
    if (DateTime.fromISO(stopDate) < Date.now()) {
      contractStatus = "motorist-contract-status-expired";
    }
    if (DateTime.fromISO(stopDate) > Date.now() && isEnabled) {
      contractStatus = "motorist-contract-status-active";
    }
    if (DateTime.fromISO(stopDate) > Date.now() && !isEnabled) {
      contractStatus = "motorist-contract-status-suspended";
    }
    const isVisitor = !currentSession.contract ? true : false;

    const { events, occupancyDuration } = currentSession;

    const ENTRY_AUTHORIZED = findLast(events, ({ state }) => state === "ENTRY_AUTHORIZED");
    const ENTRY_DENIED = findLast(events, ({ state }) => state === "ENTRY_DENIED");
    const ENTERED = findLast(events, ({ state }) => state === "ENTERED");
    const EXITED = findLast(events, ({ state }) => state === "EXITED");

    const isEntered = ENTERED ? true : false;
    const isClosed = EXITED || ENTRY_DENIED ? true : false;

    const entry = ENTERED?.createdAt ?? ENTRY_AUTHORIZED?.createdAt ?? ENTRY_DENIED?.createdAt ?? undefined;
    const exit = EXITED?.createdAt ?? undefined;
    const eventsFiltered = events?.filter((event) => !["CREATED", "EXIT_AUTHORIZE_PENDING"].includes(event.state));

    const resultData = {
      motoristInfos: motoristInfos,
      contractInfos: {
        contractId,
        ospContractId,
        isActive: isEnabled,
        startDate,
        stopDate,
        parkingName: `parking-${parkingId}-name`,
        isVisitor: isVisitor,
        status: contractStatus,
        product: product?._id,
      },
      sessionInfos: {
        isClosed,
        sessionId: sessionId,
        sessionParking: `parking-${parkingId}-name`,
        entry,
        exit,
        duration: occupancyDuration,
        parkingFee: undefined,

        isEntered,
      },
      eventList: eventsFiltered?.map((ev) => {
        const credential = ev?.credentials ? ev?.credentials?.find((c) => c?.type !== "RFID") : {};

        const accessPoint = accessPoints?.find((ap) => ap?._id === ev?.accessPointId);

        return {
          state: ev?.state,
          reason: ev?.reason,
          credentialDisplay: credential?.type
            ? credential?.type === "PROVIDER_EXTERNAL_ID"
              ? `${credential?.provider}`
              : `${credential?.type}`
            : "",
          credentialType: credential?.type,
          credentialValue: credential?.value,
          accessPointCode: accessPoint?.shortCode,
          createdAt: ev?.createdAt,
          error: false,
        };
      }),
    };
    return resultData;
  }, [currentSession]);

  if (!currentSession) {
    return <></>;
  }

  return (
    <LocalContext.Provider
      value={{
        data: renderSession,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "SessionDetails",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
