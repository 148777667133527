import React from "react";

// Material UI
import Grid from "@mui/material/Grid";

// eslint-disable-next-line max-len
import { CellCenterCenter } from "assets/@ndres-ui/MotoristTableStyled";

// @ndres-ui
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";

const MyStyled = ({ tap, onChange, optionsTabs, ...rest }) => (
  <>
    <MDBox {...rest} style={{ height: "0px" }} mx={0}>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <MDBox width="100%" height="10px" position="relative">
            <MDBox
              bgColor="#800080"
              width="100%"
              height="60px"
              style={{
                position: "absolute",
                left: tap === 0 ? `${tap * 100}%` : `${tap * 100}%`,
                transition: "all ease .5s",
                cursor: "pointer",
                boxShadow: "0px 0px 5px #b0b0b0",
              }}
            >
              <CellCenterCenter
                color="white"
                id="888dd"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  height: "100%",

                  width: `100%`,
                }}
              >
                <MDTypography
                  fontSize="0.9rem"
                  fontWeight="bold"
                  textTransform="uppercase"
                  style={{
                    color: "white",
                    position: "absolute",
                    transition: "all ease-in .1s",
                    opacity: tap === 0 ? 1 : 0,
                  }}
                >
                  {optionsTabs[0]}
                </MDTypography>
                <MDTypography
                  fontSize="0.9rem"
                  fontWeight="bold"
                  textTransform="uppercase"
                  style={{
                    color: "white",
                    position: "absolute",
                    transition: "all ease-in .1s",
                    opacity: tap === 1 ? 1 : 0,
                  }}
                >
                  {optionsTabs[1]}
                </MDTypography>
                <MDTypography
                  fontSize="0.9rem"
                  fontWeight="bold"
                  textTransform="uppercase"
                  style={{
                    color: "white",
                    position: "absolute",
                    transition: "all ease-in .1s",
                    opacity: tap === 2 ? 1 : 0,
                  }}
                >
                  {optionsTabs[2]}
                </MDTypography>
              </CellCenterCenter>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
    <MDBox style={{ margin: "0px" }} {...rest}>
      <Grid container spacing={0}>
        {optionsTabs.map((value, i) => (
          <Grid item xs={4} key={`${value}${i + 1}`}>
            <MDBox bgColor="#DDD" width="100%" height="60px" onClick={() => onChange(i)} style={{ cursor: "pointer" }}>
              <CellCenterCenter>
                <MDTypography fontSize="0.9rem" textTransform="uppercase" style={{ color: "black" }}>
                  {value}
                </MDTypography>
              </CellCenterCenter>
            </MDBox>
          </Grid>
        ))}
      </Grid>
    </MDBox>
  </>
);
export default MyStyled;
