import { gql } from "@apollo/client";

export default gql`
  mutation verifyAndUpdateUser($input: SetVerifyDto!) {
    verifyAndUpdateUser(input: $input) {
      user {
        _id
        state
      }
    }
  }
`;
