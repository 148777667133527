/* eslint-disable implicit-arrow-linebreak */
import React from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";
import MDTypography from "assets/@ndres-ui/MDTypography";
import { ProviderLogos } from "../../../commonFunctions/useProvider";

const MotoristRFIDTable = ({ cards }) => {
  const text = useDictionaries();

  return (
    <>
      <section className="section_details">
        <MDTypography className="global_xs_title" type="tableHeader" textTransform="first">
          {text("widgets-motorist-details-table-header-cards")}
        </MDTypography>
        <div className="global_separa"></div>
        <div className="place_rub">
          {cards?.map(({ type, icon, value, description }, index) => (
            <div key={`plate-${value}-${type}-${index + 1}`} className="flexTableRows">
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-externalId")}
                </MDTypography>
              </div>
              <div className="flexTableData">
                <MDTypography type="tableData">{value}</MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-type")}
                </MDTypography>
              </div>
              <div className="flexTableData">
                <div>
                  <span style={{ marginRight: "5px" }}>
                    <ProviderLogos provider={description.toUpperCase()} width="30px" height="30px" />
                  </span>
                  <MDTypography component="span" type="tableData">
                    {text(`global-providers-${description.toLowerCase()}`)}
                  </MDTypography>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default MotoristRFIDTable;
