import React from "react";
import PropTypes from "prop-types";
import PersonIcon from "@mui/icons-material/Person";

const TableLink = ({ onClick }) => {
  return (
    <>
      <PersonIcon
        className="person__icon"
        sx={{ color: "#B4AFAA ", cursor: "pointer" }}
        fontSize="medium"
        onClick={onClick}
      />

      <style jsx="true">{`
        .person__icon {
          transition: all 0.3s ease-in-out;
        }
        .person__icon:hover {
          color: #800080;
        }
      `}</style>
    </>
  );
};

TableLink.defaultProps = {
  onClick: undefined,
};

TableLink.propTypes = {
  onClick: PropTypes.func,
};

export default TableLink;
