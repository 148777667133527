import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./Layout";
import Login from "./components/Login";
import MDBox from "./assets/@ndres-ui/MDBox";
import NavigationBar from "./DashboardElements/components/layout/NavigationBar";
import LeftBarTopology from "./components/Hierarchy";
import Container from "./DashboardElements/components/layout/Container";

import UserValidation from "./components/UserValidation";
import TokenInvalid from "components/UserValidation/materials/TokenInvalid";
import TokenNotValidated from "components/UserValidation/materials/TokenNotValidated";

import { Dashboard } from "./components/DashboardPage";
import SessionPage from "./components/SessionsPage";
import { I18n } from "./components/I18n";
import { service } from "./components/Login/reducer";
import { useSelector } from "@xstate/react";
import ContractPage from "components/ContractsPage";
import WizardCreateMotoristContractPool from "./components/WizardCreateMotoristContractPool";
import WizardCreateSubContractPool from "./components/WizardCreateSubContractPool";
import BusinessTenantPage from "./components/BusinessTenantPage";
import { useSocket } from "./socket";

const LoggedWrapper = ({ children }) => {
  const navigate = useNavigate();
  const socket = useSocket();
  const connected = useSelector(service, ({ context }) => context.connected);

  useEffect(() => {
    if (!connected) {
      console.log("NAVIGATE");
      navigate("/", { replace: true });
    }
  }, [connected]);

  return (
    <MDBox width="100%" minHeight="calc(100vh)">
      <NavigationBar />

      <MDBox pl="1vw">
        <LeftBarTopology />
        <Suspense fallback={<div>Loading</div>}>
          <Container>{children}</Container>
        </Suspense>
      </MDBox>
    </MDBox>
  );
};

export const Router = () => {
  return (
    <BrowserRouter>
      <Layout>
        <I18n />
        <WizardCreateMotoristContractPool />
        <WizardCreateSubContractPool />
        <Routes>
          <Route path="/verificator/:token" element={<UserValidation />} />
          <Route path="/" element={<Login />} />
          <Route path="/verificator/invalidToken" element={<TokenInvalid />} />
          <Route path="/verificator/notValidated" element={<TokenNotValidated />} />

          <Route
            path="/dashboard"
            element={
              <LoggedWrapper>
                <Dashboard />
              </LoggedWrapper>
            }
          />
          <Route
            path="/dashboard/sessions"
            element={
              <LoggedWrapper>
                <SessionPage />
              </LoggedWrapper>
            }
          />
          <Route
            path="/dashboard/contracts"
            element={
              <LoggedWrapper>
                <ContractPage />
              </LoggedWrapper>
            }
          />

          <Route
            path="/dashboard/tenants"
            element={
              <LoggedWrapper>
                <BusinessTenantPage />
              </LoggedWrapper>
            }
          />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};
