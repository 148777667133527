import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import meGql from "./me.gql";
import productsGql from "./products.gql";
import parkingsGql from "./parkings.gql";

const userModel = createModel({
  hierarchy: {},
  nodes: {},
  parkings: {},
  parkingsIds: [],
  selectedParkings: [],
  operatorId: "",
});

const flatGraph = ({ id, children, ...rest }, nodes = {}) => {
  if (children?.length > 0) {
    const childrens = children.map((node) => flatGraph(node)).reduce((prev, current) => ({ ...prev, ...current }), {});
    return {
      ...nodes,
      ...childrens,
      [id ?? "undefined"]: { id, ...rest },
    };
  }

  return {
    ...nodes,
    [id ?? "undefined"]: { id, ...rest },
  };
};

const getParkingsOnly = (root) => {
  // Get all parkings deeply
  const reduceChildren = (acc, child) =>
    child.children ? [...child.children.reduce(reduceChildren, []), ...acc, child.parking] : [...acc, child.parking];

  // Remove undefined children
  return root.children.reduce(reduceChildren, []).filter((item) => !!item);
};

const fetchSettings = async (context) => {
  const { data } = await client.query({
    query: meGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": context.token } },
  });
  return data.me;
};

const fetchParkingss = async ({ token, operatorId }) => {
  const { data } = await client.query({
    query: parkingsGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      page: 1,
    },
  });

  return data.parkings?.list;
};
const fetchProducts = async ({ token, parkingsIds }) => {
  const { data } = await client.query({
    query: productsGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      parkingsIds,
    },
  });

  return data.products?.list;
};

const hierarchyMachine = createMachine(
  {
    context: userModel.initialContext,
    id: "machine-hierarchy",
    initial: "idle",
    states: {
      idle: {
        on: {
          "hierarchy:select-item": {
            cond: "somethingChange",
            target: "handleSelectingItem",
          },
          "hierarchy:initialize": { target: "loading" },
          "hierarchy:populate": { target: "populating" },
        },
      },
      loading: {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          src: (context, event) => fetchSettings(context, event),
          id: "getSettings",
          onDone: [{ target: "populating" }],
          onError: [{ target: "idle" }],
        },
      },
      populating: {
        entry: ["populate hierarchy"],
        always: {
          target: "gettingParkings",
        },
      },
      gettingProducts: {
        invoke: {
          src: fetchProducts,
          id: "gettingProducts",
          onDone: [{ target: "populatingProducts" }],
          onError: [{ target: "idle" }],
        },
      },
      gettingParkings: {
        invoke: {
          src: fetchParkingss,
          id: "gettingParkings",
          onDone: [{ target: "populatingParkings" }],
          onError: [{ target: "idle" }],
        },
      },
      populatingParkings: {
        entry: ["populate parkings"],
        always: {
          target: "idle",
        },
      },
      populatingProducts: {
        entry: ["populate products"],
        always: {
          target: "idle",
        },
      },
      handleSelectingItem: {
        entry: ["select parkings"],
        always: {
          target: "idle",
        },
      },
    },
  },
  {
    actions: {
      "populate products": assign({
        parkings: ({ parkings }, { data }) =>
          parkings.map((parking) => ({
            ...parking,
            products: data.filter(({ parkingId }) => parkingId === parking._id),
            withPool: data.some(({ parkingId, category }) => parkingId === parking._id && category === "OPERATOR_POOL"),
            withSubscription: data.some(
              ({ parkingId, category }) => parkingId === parking._id && category === "PERIOD_SUBSCRIPTION",
            ),
          })),
      }),
      "populate parkings": assign({
        parkings: ({ parkings }, { data }) => data,
        parkingsIds: (_, { data }) => data.map(({ _id }) => _id),
        selectedParkings: (_, { data }) => data.map(({ _id }) => _id),
      }),
      "populate hierarchy": assign({
        poolUserId: (_, { data: { _id } }) => _id,
        user: (_, { data }) => data,
      }),
      "select parkings": assign({
        selectedParkings: (ctx, { payload: { selectedParkings } }) =>
          selectedParkings.length ? selectedParkings : ctx.parkings.map(({ _id }) => _id),
      }),
    },
    guards: {
      somethingChange: (context, { payload }) => {
        const { selectedParkings: currentSelectedParkings } = context;
        const { selectedParkings: newSelectedParkings } = payload;
        const changed = newSelectedParkings.join("") !== currentSelectedParkings.join("");
        return changed;
      },
    },
  },
);

export const service = interpret(hierarchyMachine).start();

const initial = {
  state: hierarchyMachine.initialState.hierarchy,
  context: hierarchyMachine.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const loginStatus = service.send({ type, payload });
  return { state: loginStatus.hierarchy, context: loginStatus.context };
};

export default widgetReducer;
