import React, { useContext, useMemo, useState } from "react";
import { Collapse } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Paginat from "assets/@luc-ui/Pagination";
import useDictionaries from "assets/commonHooks/useDictionaries";
import MDTypography from "assets/@ndres-ui/MDTypography";

import { LocalContext } from "../../context";
import { useDispatch } from "react-redux";
import MDButton from "../../../../assets/@ndres-ui/MDButton";
import Popinfo from "../../../../assets/@redapuenta-ui/popinfo/popinfo";

const BusinessTenantsTable = () => {
  const [open, setOpen] = useState({});
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();
  const text = useDictionaries();
  const { list, page, totalPages, onSelectPage, onSelectContract } = useContext(LocalContext);

  const [popOver, setPopOver] = useState();

  function handleClick(id) {
    setOpen((prevState) => ({ [id]: !prevState[id] }));

    setSelected(id);
    onSelectContract(id);
  }
  function handleSubClick(id) {
    setSelected(id);
    onSelectContract(id);
  }
  const handleAddSingleClick = (contract) => {
    dispatch({
      type: "WizardCreateSubContractPool:wakeup",
      payload: {
        contractId: contract?._id,
        ospContractId: contract.ospContractId,
        tenantContracts: contract.tenantContracts,
        contractStatus: contract.contractStatus,
        spacesAllocated: contract.spacesAllocated,
        spacesOccupied: contract.spacesOccupied,
        name1: contract.name1,
      },
    });
  };
  const listReacive = useMemo(() => list, [list]);

  const colorSelector = {
    "motorist-contract-status-active": "success",
    "motorist-contract-status-suspended": "warning",
    "motorist-contract-status-expired": "dark",
  };

  const handleAddMotoristClick = (params) => {
    dispatch({
      type: "WizardCreateMotoristContractPool:wakeup",
      payload: {
        ...params,
      },
    });
  };

  return (
    <>
      <div style={{}}>
        <div
          style={{
            position: "relative",
            width: "100%",
            padding: "0 10px",
            display: "flex",
            flexDirection: "column",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div>
            <div className="flexTableRowsEqual" style={{ marginBottom: "0px" }}>
              <div className="flexTableHead">
                <MDTypography className="global_label" textTransform="first" type="tableHeader">
                  {text("widgets-business-tenants-details-table-header-name")}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" textTransform="first" type="tableHeader">
                  {text("widgets-business-tenants-details-table-header-name2")}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" textTransform="first" type="tableHeader">
                  {text("widgets-business-tenants-details-table-header-city")}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" textTransform="first" type="tableHeader">
                  {text("widgets-business-tenants-details-table-header-spaces")}
                </MDTypography>
              </div>
            </div>
            <div className="flexTableRowsEqual">
              <div className="flexTableHead">
                <MDTypography className="global_label" textTransform="first" type="tableHeader">
                  {text("widgets-business-tenants-details-table-header-ext-contract-id")}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" textTransform="first" type="tableHeader">
                  {text("widgets-business-tenants-details-table-header-status")}
                </MDTypography>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",

              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {listReacive?.map(
              ({
                _id,
                parkingId,
                productId,
                tenantContracts,
                contractStatus,
                spacesAllocated,
                spacesOccupied,
                name1,
                name2,
                ospContractId,
                city,
                activeMotoristsContractsCount,
                tenantCanCreateSubContract,
              }) => {
                return (
                  <div style={{ width: "100%" }} key={_id}>
                    <div
                      aria-hidden="true"
                      className="hoverPink details_accor"
                      key={_id}
                      onClick={() => handleClick(_id)}
                      style={{
                        position: "relative",
                        width: "100%",

                        background: open[_id] ? "rgb(234, 188, 235)" : "",
                      }}
                    >
                      <div className="flexTableRowsEqual" style={{ cursor: "pointer" }}>
                        <div className="flexTableHead">
                          <MDTypography textTransform="first" type="tableData">
                            {name1}
                          </MDTypography>
                        </div>
                        <div className="flexTableHead" style={{ fontWeight: "bold" }}>
                          <MDTypography textTransform="first" type="tableData">
                            {name2}
                          </MDTypography>
                        </div>
                        <div className="flexTableHead">
                          <MDTypography textTransform="first" type="tableData">
                            {city}
                          </MDTypography>
                        </div>
                        <div className="flexTableHead">
                          <MDTypography textTransform="first" type="tableData">
                            {activeMotoristsContractsCount > spacesAllocated
                              ? `${spacesOccupied} / ${spacesAllocated}`
                              : `- / ${spacesAllocated}`}
                          </MDTypography>
                        </div>
                        {tenantCanCreateSubContract ? (
                          <div
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              overflow: "visible",
                            }}
                            className="flexTableHead"
                          >
                            <MDButton
                              className="button_add"
                              circular
                              iconOnly
                              onMouseOver={() => {
                                setPopOver(_id);
                              }}
                              onMouseOut={() => {
                                setPopOver(undefined);
                              }}
                              style={{
                                color: "white",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#800080",
                              }}
                              color="light"
                              onClick={() =>
                                handleAddSingleClick({
                                  _id,
                                  tenantContracts,
                                  contractStatus,
                                  spacesAllocated,
                                  spacesOccupied,
                                  name1,
                                  name2,
                                  ospContractId,
                                  city,
                                })
                              }
                            >
                              <i style={{ fontSize: "16px" }} className="fa-solid fa-plus"></i>
                            </MDButton>
                            {popOver === _id ? (
                              <Popinfo
                                style={{ right: "60px" }}
                                textPopinfo={text("widgets-business-tenants-details-button-create-pool")}
                              />
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                      <div className="flexTableRowsEqual" style={{ cursor: "pointer", marginBottom: "0px" }}>
                        <div className="flexTableData">
                          <MDTypography type="tableData">{ospContractId}</MDTypography>
                        </div>
                        <div className="flexTableData">
                          <MDTypography type="status" textTransform="first" color={colorSelector[contractStatus]}>
                            {text(contractStatus)}
                          </MDTypography>
                        </div>
                      </div>
                    </div>
                    <Collapse style={{ width: "100%" }} in={open[_id]}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        {tenantContracts?.map(
                          ({
                            _id,
                            name1,
                            name2,
                            city,
                            tenantContractStatus,
                            spacesAllocated,
                            spacesOccupied,
                            ospContractId,
                            activeMotoristsContractsCount: activeMotoristsContractsCountChild,
                          }) => {
                            return (
                              <div
                                style={{
                                  marginBottom: "10px",
                                  paddingLeft: "20px",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                key={_id}
                              >
                                <div
                                  aria-hidden="true"
                                  className="hoverPink"
                                  onClick={() => handleSubClick(_id)}
                                  style={{
                                    width: "100%",
                                    padding: "10px 8px",
                                    background: selected === _id ? "rgb(234, 188, 235)" : "#F2F2F2",
                                    position: "relative",
                                  }}
                                >
                                  {popOver === _id ? (
                                    <Popinfo
                                      style={{ right: "60px" }}
                                      textPopinfo={text("widgets-business-tenants-details-button-add-motorist")}
                                    />
                                  ) : null}
                                  <div
                                    style={{
                                      zIndex: "2",
                                      transform: "translateY(-50%)",
                                      position: "absolute",
                                      right: "10px",
                                      top: "50%",
                                    }}
                                  >
                                    <MDButton
                                      className="button_add"
                                      circular
                                      iconOnly
                                      onMouseOver={() => {
                                        setPopOver(_id);
                                      }}
                                      onMouseOut={() => {
                                        setPopOver(undefined);
                                      }}
                                      color="light"
                                      onClick={() => handleAddMotoristClick({ contractId: _id, productId, parkingId })}
                                      style={{
                                        backgroundColor: "#800080",

                                        color: "white",
                                      }}
                                    >
                                      +N
                                    </MDButton>
                                  </div>
                                  <div
                                    className="flexTableRowsEqual"
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <div className="flexTableHead">
                                      <MDTypography type="tableData" textTransform="first">
                                        {name1}
                                      </MDTypography>
                                    </div>
                                    <div className="flexTableHead">
                                      <MDTypography type="tableData" textTransform="first">
                                        {name2}
                                      </MDTypography>
                                    </div>
                                    <div className="flexTableHead">
                                      <MDTypography type="tableData" textTransform="first">
                                        {city}
                                      </MDTypography>
                                    </div>
                                    <div className="flexTableHead">
                                      <MDTypography type="tableData">
                                        {parseInt(activeMotoristsContractsCountChild) > spacesAllocated
                                          ? `${spacesOccupied} / ${spacesAllocated}`
                                          : `- / ${spacesAllocated}`}
                                      </MDTypography>
                                    </div>
                                  </div>
                                  <div
                                    aria-hidden="true"
                                    className="flexTableRowsEqual"
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    <div className="flexTableData">
                                      {<MDTypography type="tableData">{`${ospContractId}`.toUpperCase()}</MDTypography>}
                                    </div>
                                    <div className="flexTableData">
                                      <MDTypography
                                        type="status"
                                        color={colorSelector[tenantContractStatus]}
                                        textTransform="first"
                                      >
                                        {text(tenantContractStatus)}
                                      </MDTypography>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          },
                        )}
                      </div>
                    </Collapse>
                  </div>
                );
              },
            )}
          </div>
        </div>
        <style jsx="true">
          {`
            .button_add {
              transition: all 0.3s ease-in-out;
            }

            .button_add:hover {
              transform: scale(0.9);
            }
          `}
        </style>
        <Paginat page={page} totalPages={totalPages} onSelectPage={onSelectPage} />
      </div>
    </>
  );
};

export default BusinessTenantsTable;
