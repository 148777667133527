import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";
import { DateTime } from "luxon";

import { service } from "../reducer";
import { service as tenantsDetailsService } from "../../BusinessTenantsDetails/reducer";

const defaultContextData = {
  list: [],
  page: 1,
  totalPages: 1,
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  const { list, paging } = useSelector(service, ({ context: { list, paging } }) => {
    return { list, paging };
  });

  const selectedParkings = useSelector(service, ({ context: { selectedParkings } }) => selectedParkings);
  const poolContract = useSelector(tenantsDetailsService, ({ context: { poolContract } }) => poolContract);

  const selectedBusinessTenants = useSelector(
    service,
    ({ context: { selectedMotorist } }) => selectedMotorist,
    (old, newSession) => old?._id !== newSession?._id,
  );

  useEffect(() => {
    dispatch({ type: `${storeName}:wakeup`, payload: {} });
    return () => {
      dispatch({ type: `${storeName}:sleep`, payload: {} });
    };
  }, []);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(() =>
    Object.entries(events).reduce(
      (acc, [key, fn]) => ({
        [key]: (src) => eventDispatch(key, fn, src),
        ...acc,
      }),
      {},
    ),
  );

  const renderList = useMemo(
    () =>
      list?.map(
        ({
          _id,
          operatorId,
          parkingId,
          productId,
          parking,
          isEnabled,
          category,
          tenantContracts,
          operatorContract,
          ospContractId,
          spacesAllocated,
          spacesOccupied,
          startDate,
          stopDate,
          motoristsContracts,
          name1,
          name2,
          city,
          activeMotoristsContractsCount,
          tenantCanCreateSubContract,
        }) => {
          let contractStatus = "";
          if (DateTime.fromISO(stopDate) < Date.now()) {
            contractStatus = "motorist-contract-status-expired";
            isEnabled = false;
          }
          if (DateTime.fromISO(stopDate) > Date.now() && isEnabled) {
            contractStatus = "motorist-contract-status-active";
          }
          if (DateTime.fromISO(stopDate) > Date.now() && !isEnabled) {
            contractStatus = "motorist-contract-status-suspended";
          }
          //TenantsContracts map
          tenantContracts = tenantContracts.map(
            ({
              stopDate,
              isEnabled,
              _id,
              spacesAllocated,
              spacesOccupied,
              name1,
              ospContractId,
              contractCount,
              activeMotoristsContractsCount: activeMotoristsContractsCountChild,
            }) => {
              let tenantContractStatus = "";
              if (DateTime.fromISO(stopDate) < Date.now()) {
                tenantContractStatus = "motorist-contract-status-expired";
                isEnabled = false;
              }
              if (DateTime.fromISO(stopDate) > Date.now() && isEnabled) {
                tenantContractStatus = "motorist-contract-status-active";
              }
              if (DateTime.fromISO(stopDate) > Date.now() && !isEnabled) {
                tenantContractStatus = "motorist-contract-status-suspended";
              }
              return {
                _id,
                ospContractId,
                tenantContractStatus,
                spacesOccupied,
                spacesAllocated,
                motoristsContracts,
                name1,
                contractCount,
                activeMotoristsContractsCount: activeMotoristsContractsCountChild,
              };
            },
          );

          return {
            _id,
            productId,
            ospContractId,
            operatorId,
            contractStatus,
            spacesAllocated,
            spacesOccupied,
            parkingId,
            parking,
            isEnabled,
            category,
            tenantContracts,
            operatorContract,
            startDate,
            stopDate,
            name1,
            name2,
            city,
            motoristsContracts,
            activeMotoristsContractsCount,
            tenantCanCreateSubContract,
          };
        },
      ),
    [list, selectedParkings, poolContract],
  );

  const renderPaging = useMemo(() => {
    if (!paging) return;
    const { count = 0, limit = 10 } = paging;
    const total = Math.ceil(count / limit);
    return {
      page: paging?.current,
      totalPages: total,
    };
  }, [paging]);
  return (
    <LocalContext.Provider
      value={{
        list: renderList,
        selectedBusinessTenants: selectedBusinessTenants,
        ...renderPaging,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "BusinessTenants",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
