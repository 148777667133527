import React from "react";
import IncidentsTable from "./materials/table";
import ContainerInGrid from "assets/@luc-ui/Container";
import { ContextProvider } from "./context";

const LastIncidents = () => {
  const onSelectPage = (page) => ({ page });
  const onSelectIncident = (eventContext) => eventContext;

  return (
    <div style={{ height: "100%", overflow: "scroll", position: "relative" }}>
      <ContainerInGrid headerName="widgets-incidents-title" headerTextColor="light" headerBackgroundColor="#800080">
        <ContextProvider
          storeName="LastIncidents"
          events={{
            onSelectPage,
            onSelectIncident,
          }}
        >
          <IncidentsTable />
        </ContextProvider>
      </ContainerInGrid>
    </div>
  );
};

export default LastIncidents;
