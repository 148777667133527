import { gql } from "@apollo/client";

export default gql`
  query poolContracts($category: String, $channels: [ChannelInput], $page: Int, $limit: Int) {
    poolContractsAndSub(category: $category, channels: $channels, page: $page, limit: $limit) {
      list {
        _id
        operatorId
        parkingId
        _id
        name1
        name2
        city
        isEnabled
        spacesOccupied
        category
        spacesAllocated
        ospContractId
        productId
        activeMotoristsContractsCount
        parking {
          _id
        }
        operatorContract {
          _id
          product {
            name
          }
          stopDate
          startDate
          isEnabled
          name1
          name2
          city
          ospContractId
        }
        tenantCanCreateSubContract
        tenantContracts {
          name1
          stopDate
          startDate
          _id
          spacesOccupied
          isEnabled
          category
          spacesAllocated
          ospContractId
          activeMotoristsContractsCount
          motoristsContracts {
            paging {
              count
            }
          }
        }
        startDate
        stopDate
        motoristsContracts {
          paging {
            count
          }
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
