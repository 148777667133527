import * as React from "react";

// locals MUI
import MDBox from "assets/@ndres-ui/MDBox";

import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import { Popper } from "@mui/material";
import { SubMenu } from "./SubMenu";
import Fade from "@mui/material/Fade";

import Ostermundigen from "assets/images/icons/logo_Ostermundigen.png";
import parkingLuzern from "assets/images/icons/cff.png";
import parkingLogo from "assets/images/icons/parking.svg";
const DashboardButton = styled(({ inside, selectedLayer, ...rest }) => <MDBox {...rest} />)(() => ({
  color: "#adadad",
  "&:hover": {
    color: "#7d7d7d",
  },
}));

const MenuBoxInside = styled(MDBox)(() => ({
  background: "white",
  border: "1px solid #ababab90",
  borderRadius: "5px",
  width: "100%",
  "&:hover": {},
}));

export const ParkingCards = ({
  handlePopoverClose,
  handleClick,
  openMenu,
  label,
  parking,
  selectedParkings,
  openPopoverId,
  setSelection,
  anchorEl,
}) => {
  const text = useDictionaries();

  const imageSelector = {
    CH_OST_0001: Ostermundigen,
    CH_IEM_0001: parkingLuzern,
    CH_WIN_001: parkingLogo,
    DEFAULT: parkingLuzern,
  };

  return (
    <>
      <MDBox
        role="button"
        cursor="pointer"
        height="40px"
        border="1px solid #ababab90"
        marginTop="3px"
        marginBottom="3px"
        width={`97%`}
        borderRadius="4px"
        bgColor="White"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        onClick={() => handleClick([parking._id])}
        onMouseEnter={handlePopoverClose}
      >
        <MDBox
          width="100%"
          height={"40px"}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          bgColor={selectedParkings?.includes(parking._id) && selectedParkings.length === 1 ? "#eabceb" : "transparent"}
          style={{ transition: !selectedParkings ? "none" : "ease 1s" }}
        >
          <MDBox display="flex" justifyContent="flex-start" alignItems="center" height="100%" width="100%" pt={0}>
            <MDBox
              height={"30px"}
              width={"30px"}
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
              pt={0}
            >
              <MDBox
                height={"30px"}
                width={"30px"}
                position="relative"
                display="flex"
                alignItems="center"
                justifyContent="center"
                pt={0}
              >
                <img
                  src={imageSelector[parking?.code] ? imageSelector[parking?.code] : imageSelector["DEFAULT"]}
                  height={"25px"}
                  width={"25px"}
                  alt="container logo"
                />
              </MDBox>
            </MDBox>
            <MDTypography
              fontSize="1em"
              px={0}
              pt="0px"
              ml={1}
              textTransform="capitalize"
              width={`calc(100% - "40px")`}
              style={{ lineHeight: "15px", verticalAlign: "middle" }}
            >
              <span style={{ textOverflow: "ellipsis" }}>{text(`parking-${parking._id}-name`)}</span>
            </MDTypography>
          </MDBox>
          <MDBox height="100%" display="flex" alignItems="center" justifyContent="flex-end" pt={1.1}>
            <DashboardButton
              onMouseEnter={(event) => {
                openMenu(event, parking._id);
              }}
            >
              <MoreVertIcon style={{ width: "22px", height: "22px" }} />
            </DashboardButton>
          </MDBox>
        </MDBox>
        <Popper
          className="popper-root"
          id={"id"}
          open={openPopoverId === parking._id}
          anchorEl={anchorEl}
          transition
          placement="right-start"
          style={{ position: "fixed", zIndex: 9 }}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [-11.3, -2],
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={100}>
              <MenuBoxInside onMouseLeave={() => handlePopoverClose()}>
                <SubMenu
                  onClose={handlePopoverClose}
                  setSelection={setSelection}
                  parkingsIds={[parking._id]}
                  parking={parking}
                />
              </MenuBoxInside>
            </Fade>
          )}
        </Popper>
      </MDBox>
    </>
  );
};
