import React from "react";
import PropTypes from "prop-types";

import Pagination from "@mui/material/Pagination";

const Paginat = ({ totalPages, onSelectPage, page, position, style }) => {
  if (totalPages <= 1) {
    return <></>;
  }

  return (
    <div
      style={{
        width: "100%",
        marginTop: "2em",
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: position ? position : "fixed",
          bottom: "0.1em",
          background: "white",
          width: "100%",
        }}
      >
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, newPage) => onSelectPage(newPage)}
          variant="outlined"
          shape="rounded"
          size="medium"
        />
      </div>
    </div>
  );
};

Paginat.propTypes = {
  totalPages: PropTypes.number,
  page: PropTypes.number,
  onSelectPage: PropTypes.func,
};

export default Paginat;
