import * as React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
// web.cjs is required for IE11 support
import { useSpring, animated } from "react-spring";

// Material ui
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { withStyles } from "@mui/styles";

// Material icons
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import boxShadows from "assets/@ndres-ui/theme/base/boxShadows";
import colors from "assets/@ndres-ui/theme/base/colors";

const { primary } = colors;

const ListItem = withStyles({
  root: {
    borderRadius: "5px",
    transition: "background-color 0.5s ease-out, color 0.5s ease-out",
    color: "grey",
    "&:hover": {
      backgroundColor: primary.light,
      color: "black",
      "& .MuiListItemIcon-root": {
        color: "black",
      },
    },
  },
  selected: {},
})(ListItemButton);

const { colored } = boxShadows;

const styleListItemP = { fontSize: "0.8rem", paddingLeft: "5px" };

export function NestedList() {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch({ type: "login:logout", payload: {} });
  };

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        borderRadius: "5px",
        margin: "0px 0px",
        boxShadow: colored.dark,
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItem onClick={logout}>
        <PowerSettingsNewIcon />
        <p style={styleListItemP}>Sign out</p>
      </ListItem>
    </List>
  );
}

export const Fade = React.forwardRef((props, ref) => {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
};
