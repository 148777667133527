import "./search.scss";
import { useSelector } from "@xstate/react";
import { useEffect, useState } from "react";
import { service } from "./reducer";
// import { service as serviceHierarchy } from "../../../../../components/Hierarchy/reducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useDictionaries from "../../../../../assets/commonHooks/useDictionaries";
import { gsap } from "gsap";

const SearchBar = () => {
  const text = useDictionaries();

  const [filterItems, setFilterItems] = useState([
    { text: "search-filter-all-result", data: ["motorists", "localMotorists"], total: 0 },
    { text: "search-filter-motorists", data: ["motorists"], total: 0 },
  ]);

  const [filterActive, setFilterActive] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  const inputCurrentValue = useSelector(service, ({ context: { inputCurrentValue } }) => inputCurrentValue);
  const inputCurrentChoice = useSelector(service, ({ context: { inputCurrentChoice } }) => inputCurrentChoice);
  const isOpenFilter = useSelector(service, ({ context: { isOpenFilter } }) => isOpenFilter);
  const isLoading = useSelector(service, ({ context: { isLoading } }) => isLoading);
  const filterData = useSelector(service, ({ context: { filterData } }) => filterData);

  // const selectedParkings = useSelector(serviceHierarchy, ({ context }) => context.selectedParkings);

  const motoristValue = () =>
    filterData?.motorists?.map(({ _id, lastName, firstName, username, shortUid, credentials }) => {
      const labels = [`${firstName} ${lastName}`, username, `OSP_${shortUid}`].reduce((acc, value) => {
        if (value && value?.trim() !== "") acc.push(value);
        return acc;
      }, []);

      const renderedCredentials = credentials
        .filter(({ type }) => ["PLATE", "PROVIDER_EXTERNAL_ID"].includes(type))
        .map(({ type, value, provider }) => (type === "PLATE" ? `${value}` : `${provider} ${value}`));

      labels.push(...renderedCredentials);

      return { labels, _id, type: "motorists", icon: "fa-solid fa-address-card" };
    });

  const { _, send } = service;

  const [timeoutSearch, setTimeoutSearch] = useState();
  const [tremor, setTremor] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (filterItems && filterActive >= 0) {
      const currentFilterItemData = Array.from(filterItems)[filterActive].data;

      const injectData = currentFilterItemData
        .map((value) => {
          switch (value) {
            case "motorists":
              return motoristValue();
              break;
          }
        })
        .filter((value) => value !== undefined)
        .flat(1);

      setCurrentData([...injectData]);

      const filterItemsUpdated = filterItems.map((value) => {
        const total = value?.data?.reduce((acc, valueData) => {
          let add;
          switch (valueData) {
            case "motorists":
              add = motoristValue()?.length;
              break;
          }

          const addVerified = typeof add === "number" ? add : 0;
          acc = acc + addVerified;
          return acc;
        }, 0);

        return { ...value, total };
      });

      setFilterItems(filterItemsUpdated);
    }
  }, [filterActive, filterData]);

  useEffect(() => {
    if (
      tremor &&
      inputCurrentValue.length >= 3 &&
      filterData?.motorists?.length === 0 &&
      filterData?.localMotorists?.length === 0
    ) {
      const timeline = gsap.timeline({
        defaults: {
          duration: 0.05,
        },
      });

      for (let i = 0; i < 4; i++) {
        timeline.to(".search__bar", {
          translateX: "2px",
        });

        timeline.to(".search__bar", {
          translateX: "-2px",
        });
      }
    }
  }, [filterData]);

  useEffect(() => {
    setFilterActive(0);
  }, [isOpenFilter]);

  const switchToAllMotorist = () => {
    dispatch({
      type: "Motorists:onSelectMotorist",
      payload: { selectedMotorist: true },
    });
  };

  //! ----------------------------------------------------------------------------
  //! ------ JE SUIS FIER DE CE USE-EFFECT DONC PAS TOUCHE by RedaPuenta ---------
  //! ----------------------------------------------------------------------------
  useEffect(() => {
    const click = (e) => {
      const clickX = e.clientX;
      const clickY = e.clientY;

      const elementInSearch = [".search__bar", ".search__result"];

      const giveMeAccord =
        elementInSearch.reduce((acc, value) => {
          const currentElement = () => document.querySelector(value);

          if (!currentElement()) return acc + 1;

          const { left, width, top, height } = currentElement()?.getBoundingClientRect();

          const startX = left;
          const endX = left + width;
          const startY = top;
          const endY = top + height;

          if (startX <= clickX && clickX <= endX && startY <= clickY && clickY <= endY) return acc;

          return acc + 1;
        }, 0) === elementInSearch.length;

      send({ type: "SEARCH_FOCUS", state: !giveMeAccord });
    };

    window.addEventListener("click", click);

    return () => {
      window.removeEventListener("click", click);
    };
  }, []);

  useEffect(() => {
    const { type } = inputCurrentChoice;
    if (!inputCurrentChoice) {
      dispatch({
        type: `global:selected-all-motorist`,
      });
      return;
    }

    dispatch({
      type: `global:selected-filtered-motorist`,
      payload: { selectedFilterMotorist: { motorist: inputCurrentChoice } },
    });

    switch (type) {
      case "motorists":
        navigate("/dashboard/contracts", { replace: true });
        break;
    }
  }, [inputCurrentChoice]);

  return (
    <div className="search">
      <div className="search__bar">
        <i
          onClick={() => {
            setTremor(true);
            send("FILTER_DATA_FILL");
          }}
          className="search__bar__icon search__bar__icon--search fa-solid fa-magnifying-glass"
        ></i>
        <i
          onClick={() => {
            setTremor(false);
            send({ type: "SEARCH_RESET" });
            switchToAllMotorist();
          }}
          className="search__bar__icon search__bar__icon--cross fa-solid fa-xmark"
        ></i>
        <input
          type="text"
          value={inputCurrentValue}
          onChange={(e) => {
            send({ type: "INPUT_WRITE", value: e.target.value });
            if (timeoutSearch) clearTimeout(timeoutSearch);
            setTimeoutSearch(
              setTimeout(() => {
                setTremor(true);
                e.target.value.length >= 3 ? send("FILTER_DATA_FILL") : send("FILTER_DATA_CLEAR");
              }, 300),
            );
          }}
          placeholder={text("search-input-placeholder")}
          className="search__bar__input"
        />
      </div>

      <div className="search__result">
        {isOpenFilter === true ? (
          <div className="search__result__filter">
            {filterItems.map(({ text: textFilter, data, total }, index) => {
              return (
                <div
                  key={`${text}${index}`}
                  onClick={() => setFilterActive(index)}
                  className={`search__result__filter__item ${filterActive === index ? "active" : ""}`}
                >
                  <span className="search__result__filter__item__name">{text(textFilter)}</span>
                  <div className="search__result__filter__item__number">
                    <span>{total}</span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
        <div className="search__result__contain">
          {isOpenFilter === true ? (
            <div className="search__result__contain__list">
              {currentData?.length !== 0 ? (
                currentData?.map(({ labels, _id, type, icon }) => {
                  return (
                    <div
                      key={_id}
                      id={_id}
                      onClick={() => send({ type: "FILTER_SELECT_CHOICE", choice: { _id, labels, type } })}
                      className="search__result__contain__list__line"
                    >
                      <i className={`search__result__contain__list__line__icon ${icon}`}></i>
                      {/* <span className="search__result__contain__list__line__type">{text("search-filter-label")} :</span> */}
                      <div className="search__result__contain__list__line__data">
                        {labels.map((value, indexLabel) => {
                          return (
                            <span
                              key={`${value}-${indexLabel}-${_id}`}
                              dangerouslySetInnerHTML={{
                                __html:
                                  value?.replaceAll(
                                    inputCurrentValue,
                                    `<b style="font-weight: 600;">${inputCurrentValue}</b>`,
                                  ) +
                                  "&nbsp" +
                                  `${indexLabel !== labels.length - 1 ? "|&nbsp" : ""}`,
                              }}
                            ></span>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              ) : isLoading ? (
                <div className="search__result__contain__list__load">
                  <div className="search__result__contain__list__load__contain">
                    <div className="search__result__contain__list__load__contain__icon"></div>
                  </div>
                </div>
              ) : (
                <div className="search__result__contain__list__empty">
                  <span>{text("search-filter-empty")}</span>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
