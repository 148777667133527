import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import motoristsGql from "./motorists.gql";

const userModel = createModel({
  token: "",
  list: [],
  paging: {},
  selectedMotorist: undefined,
  selectedFilterMotorist: undefined,
  selectedSearch: false,
  selectedParkings: [],
});

const fetchList = async ({ token, selectedParkings }, { newPage }) => {
  const { data } = await client.query({
    query: motoristsGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      order: "lastName",
      page: newPage,
      limit: 16,
      parkingsIds: selectedParkings,
    },
  });
  return data.motorists;
};

const motorists = createMachine(
  {
    context: userModel.initialContext,
    id: "Motorists",
    initial: "off",
    states: {
      off: {
        on: {
          "Motorists:wakeup": [
            { cond: "has selected parkings", target: "loading" },
            { cond: "has no parkings", target: "idle" },
          ],
          "global:selected-parkings": { actions: "save parkings" },
          "global:selected-filtered-motorist": {
            actions: "select motorist from focus",
          },
          "global:selected-all-motorist": {
            actions: "switch to all motorist",
          },
        },
      },
      idle: {
        on: {
          "global:selected-filtered-motorist": {
            actions: "select motorist from focus",
          },
          "global:selected-all-motorist": {
            actions: "switch to all motorist",
          },
          "events:motorists:created": { target: "loading" },
          "events:motorists:updated": {
            target: "loading",
          },
          "Motorists:onSelectPage": { target: "changingPage" },
          "Motorists:onSelectMotorist": { actions: "select motorist" },
          "Motorists:sleep": { target: "off", actions: "reset incident" },
          "global:selected-parkings": {
            actions: "save parkings",
            target: "loading",
          },
        },
      },
      loading: {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          id: "getMotorists",
          src: (context, event) => fetchList(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      changingPage: {
        invoke: {
          id: "getNewPage",
          src: (context, { payload }) => fetchList(context, payload),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populate: assign({
        list: (_, { data }) => data.list,
        paging: (_, { data }) => data.paging,
      }),
      "save parkings": assign({
        selectedFilterMotorist: (ctx, { payload }) => null,
        selectedMotorist: (ctx, { payload }) => null,
        selectedParkings: (ctx, { payload }) => payload.selectedParkings,
      }),
      "select motorist": assign({
        selectedMotorist: (ctx, { payload }) => {
          return payload.selectedMotorist;
        },
      }),
      "select motorist from focus": assign({
        selectedFilterMotorist: (ctx, { payload }) => payload.selectedFilterMotorist,
        selectedMotorist: (ctx, { payload }) => payload.selectedFilterMotorist?.motorist?._id,
      }),
      "switch to all motorist": assign({
        selectedFilterMotorist: (ctx, { payload }) => null,
        selectedMotorist: (ctx, { payload }) => null,
      }),
      "reset incident": assign({
        selectedFilterMotorist: () => undefined,
      }),
    },
    guards: {
      "has selected parkings": ({ selectedParkings }) => !!selectedParkings.length,
      "has no parkings": ({ selectedParkings }) => !selectedParkings.length,
    },
  },
);

export const service = interpret(motorists).start();

const initial = {
  state: motorists.initialState.list,
  context: motorists.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
