/* eslint-disable implicit-arrow-linebreak */
import React, { useContext, useMemo } from "react";
import useDictionaries from "assets/commonHooks/useDictionaries";
import TenantsTable from "assets/@luc-ui/BusinessTenantsDetails/TenantsTable";

import { LocalContext } from "../../../context";
import { LocalContext as BusinessTenantsDetailsContext } from "../../../../context";

const BusinessTenantsAboutTable = () => {
  const text = useDictionaries();
  const { onContractStatusChange, onContractPoolUpdate } = useContext(BusinessTenantsDetailsContext);

  const { poolContract } = useContext(LocalContext);

  const renderedData = useMemo(() => poolContract, [poolContract, text]);

  if (!renderedData.tenant) return <></>;

  const { tenant, contract, subContract } = renderedData;

  return (
    <>
      <div>
        <TenantsTable
          tenant={tenant}
          contract={contract}
          isParent={true}
          allowEditing={false}
          title={text("widgets-business-tenant-details-table-header-contract-details")}
        />
      </div>
      <div style={{ paddingTop: "5em" }}>
        <TenantsTable
          onContractSwitch={onContractStatusChange}
          onContractUpdate={onContractPoolUpdate}
          tenant={tenant}
          contract={subContract}
          isParent={false}
          allowEditing={true}
          allowEditingSpaces={true}
          title={text("widgets-business-tenant-details-table-header-sub-contract-details")}
        />
      </div>
    </>
  );
};

export default BusinessTenantsAboutTable;
