import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import verifyUserGql from "./verifyUser.gql";
import updateUserGql from "./updateUser.gql";
const userModel = createModel({
  token: "",
});

const fetchUser = async ({ token }, { payload }) =>
  new Promise(async (resolve, reject) => {
    console.log("🚀 ~ file: reducer.js:12 ~ payload:", payload);
    client
      .query({
        query: verifyUserGql,
        fetchPolicy: "cache-first",
        context: { headers: { "x-access-token": token } },
        variables: {
          token: payload.token,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

const updateUser = async ({ token }, { payload }) =>
  new Promise(async (resolve, reject) => {
    client
      .mutate({
        mutation: updateUserGql,
        context: { headers: { "x-access-token": token } },
        variables: {
          input: {
            token: payload.data.token,
            user: payload.data.user,
          },
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

const userValidation = createMachine(
  {
    context: userModel.initialContext,
    id: "userValidation",
    initial: "off",
    states: {
      off: {
        on: {
          "global:selected-parkings": { actions: "save parkings" },
          "UserValidation:wakeup": { target: "hydrate wakeup" },
        },
      },
      idle: {
        on: {
          "UserValidation:submitValidation": { target: "update user" },
          "UserValidation:sleep": { target: "off" },
        },
      },
      "hydrate wakeup": {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          id: "hydrate validator",
          src: (context, event) => fetchUser(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ actions: "populate token error" }, { target: "idle" }],
        },
      },
      "update user": {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          id: "update user",
          src: (context, event) => updateUser(context, event),
          onDone: [{ actions: "populate update", target: "idle" }],
          onError: [{ actions: "populate update error" }, { target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populate: assign({
        verifyData: (_, { data }) => data.verifyUser.user,
        fieldsToVerify: (_, { data }) => data.verifyUser.fieldsToVerify,
        tokenError: () => false,
      }),
      "populate token error": assign({
        verifyData: (_, { data }) => data.message,
        tokenError: () => true,
      }),
      "populate update": assign({
        updatedUser: (_, { data }) => data,
      }),
      "populate update error": assign({
        updatedUser: (_, { data }) => data.message,
      }),
      "save parkings": assign({
        selectedParkings: (ctx, { payload }) => payload.selectedParkings,
      }),
    },
  },
);

export const service = interpret(userValidation).start();

const initial = {
  state: userValidation.initialState.list,
  context: userValidation.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
