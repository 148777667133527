/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Collapse from "@mui/material/Collapse";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import useDictionaries from "assets/commonHooks/useDictionaries";

import MDTypography from "assets/@ndres-ui/MDTypography";
import MDButton from "assets/@ndres-ui/MDButton";
import MDBox from "assets/@ndres-ui/MDBox";

import Grid from "@mui/material/Grid";
import { ProviderLogos } from "../../../commonFunctions/useProvider";
import { Switch } from "@mui/material";
import { DateTime } from "luxon";
import { subDays, format, addDays } from "date-fns";
import DatePicker from "rsuite/DatePicker";
import DateRangePicker from "rsuite/DateRangePicker";
import Popup from "assets/@redapuenta-ui/popup/popup";
import { service } from "components/BusinessTenantsDetails/reducer";
import { useSelector } from "@xstate/react";
import { isToday } from "../../../commonFunctions/isToday";

const initForm = {
  startDate: new Date(),
  stopDate: new Date(),
};

const ContractDetailsTable = ({ motoristContracts, onContractSwitch, onContractUpdate, subContract, isParent }) => {
  const text = useDictionaries();

  const dispatch = useDispatch();
  const [open, setOpen] = useState({});
  const [formData, setFormData] = useState({
    ...initForm,
  });

  const [titlePopup, setTitlePopup] = useState();
  const [textPopup, setTextPopup] = useState();
  const [modePopup, setModePopup] = useState("choice");
  const [openPopup, setOpenPopup] = useState(false);
  const [currentContract, setCurrentContract] = useState({});

  const [isEditable, setEditable] = useState(false);
  const { allowedRange, beforeToday, combine } = DateRangePicker;

  const [datePickerPrevStartDate, setDatePickerPrevStartDate] = useState(new Date());
  const [datePickerPrevEndDate, setDatePickerPrevEndDate] = useState(new Date());

  //! Selector for Popup
  const updateContract = useSelector(service, ({ context }) => context.poolContract);
  const errorUpdateContract = useSelector(service, ({ context }) => context.errorUpdateContract);

  //! SUCCESS for Popup
  useEffect(() => {
    if (updateContract?.contractUpdateTarget === currentContract?.contractId && openPopup) {
      setTextPopup({ type: "success", value: text("popup-business-tenants-details-contract-terminate-success") });
      setModePopup("result");
    }
  }, [updateContract]);

  //! ERROR for Popup
  useEffect(() => {
    if (errorUpdateContract) {
      setTitlePopup({
        type: "basic",
        value: text(`global-error-title-${new String(errorUpdateContract).toLowerCase()}`),
      });
      setTextPopup({
        type: "error",
        value: text(`global-error-text-${new String(errorUpdateContract).toLowerCase()}`),
      });
      setModePopup("result");
      setOpenPopup(true);
    }
  }, [errorUpdateContract]);

  //! Open Popup
  const terminateClick = async (contractId, firstName, lastName) => {
    setModePopup("choice");
    setTitlePopup({ type: "basic", value: text("global-confirm-terminate-contract") });
    setTextPopup(undefined);
    setFormData({ stopDate: new Date() });
    setCurrentContract({ contractId, firstName, lastName });
    setOpenPopup(true);
  };

  //! Choice in Popup
  const popupChoice = async (choice) => {
    if (!choice) return setOpenPopup(false);

    const { contractId } = currentContract;

    dispatch({
      type: "WizardCreateMotoristContractPool:reset-error",
      payload: {},
    });

    setModePopup("load");
    setFormData({ stopDate: new Date() });
    onContractUpdate({ contractId: contractId, input: formData });
  };

  //! Exit Popup
  const popupExit = () => {
    dispatch({
      type: "WizardCreateMotoristContractPool:reset-error",
      payload: {},
    });
    setOpenPopup(false);
    setModePopup("choice");
  };

  function handleClick(id) {
    setEditable(false);
    setFormData({ ...initForm });
    setOpen((prevState) => ({ [id]: !prevState[id] }));
  }

  const handleEditClick = (startDate, stopDate) => {
    setEditable(!isEditable);
    setFormData({ startDate: startDate, stopDate: stopDate });
  };

  const handleSaveClick = (contractId) => {
    setEditable(!isEditable);
    onContractUpdate({ contractId: contractId, input: formData });
  };

  const handleAddClick = (params) => {
    dispatch({
      type: "WizardCreateMotoristContractPool:wakeup",
      payload: {
        parkingId: subContract?.parking?._id,
        productId: subContract?.product?._id,
        contractId: subContract?._id,
      },
    });
  };

  const handleAddSingleClick = () => {
    dispatch({
      type: "WizardCreateSingleMotoristContractPool:wakeup",
      payload: {
        parkingId: subContract?.parking?._id,
        productId: subContract?.product?._id,
        contractId: subContract?._id,
      },
    });
  };

  useEffect(() => {
    console.log(motoristContracts);
  }, [motoristContracts]);

  return (
    <>
      {openPopup ? (
        <Popup
          titlePopup={titlePopup}
          textPopup={textPopup}
          modePopup={modePopup}
          handlePopupChoice={popupChoice}
          handlePopupExit={popupExit}
        />
      ) : null}

      <section className="section_details">
        <MDTypography className="global_xs_title" type="tableHeader" textTransform="first">
          {text("widgets-motorist-motorist-section-title")}
        </MDTypography>

        <div className="global_separa"></div>
        <div className="place_rub">
          <div className="flexTableRowsFiveFirst" style={{ padding: "0px 5px 10px 5px" }}>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenants-details-motorists-table-header-credentials")}
              </MDTypography>
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenants-details-motorists-table-header-status")}
              </MDTypography>
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenants-details-motorists-table-header-email")}
              </MDTypography>
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenants-details-motorists-table-header-lastname")}
              </MDTypography>
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-business-tenants-details-motorists-table-header-firstname")}
              </MDTypography>
            </div>

            <div className="flexTableHeadXSmall"></div>
          </div>
          <div style={{ height: "100%", overflowY: "auto", paddingTop: "5px" }}>
            {motoristContracts?.map(
              (
                {
                  contractId,
                  motoristId,
                  username,
                  firstName,
                  lastName,
                  ospContractId,
                  externalProvider,
                  credentials,
                  isValid,
                  isActive,
                  status,
                  startDate,
                  endDate,
                },
                index,
              ) => {
                const contractStatus = {
                  "motorist-contract-status-active": "success",
                  "motorist-contract-status-suspended": "warning",
                  "motorist-contract-status-expired": "dark",
                };
                return (
                  <div
                    key={contractId}
                    style={{
                      backgroundColor: open[contractId] ? "#fff" : "",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                      onClick={() => handleClick(contractId)}
                    >
                      <div
                        className="flexTableRowsFiveFirst hoverPink details_accor"
                        style={{
                          cursor: "pointer",
                          position: "relative",
                        }}
                      >
                        <div className="flexTableHead">
                          {credentials?.find(({ provider }) => provider === "LOCAL") ? (
                            <span style={{ marginRight: "5px" }}>
                              <ProviderLogos provider={"LOCAL"} type={"RFID"} width={"25px"} height={"25px"} />
                            </span>
                          ) : null}

                          {credentials?.map(({ value, type, provider, description }, index) => {
                            return (
                              <span key={value + index}>
                                {type !== "RFID" && provider !== "LOCAL" ? (
                                  <span style={{ marginRight: "5px" }}>
                                    <ProviderLogos provider={provider} type={type} width={"25px"} height={"25px"} />
                                  </span>
                                ) : null}

                                <MDTypography
                                  key={value}
                                  component="span"
                                  type="status"
                                  color={isValid ? "inherit" : "error"}
                                  mr="5px"
                                >
                                  {type !== "QRCODE"
                                    ? type === "RFID" && provider === "LOCAL" && description
                                      ? description
                                      : type !== "RFID"
                                      ? value
                                      : ""
                                    : ""}
                                </MDTypography>
                              </span>
                            );
                          })}
                        </div>
                        <div className="flexTableHead">
                          <MDTypography
                            component="span"
                            type="status"
                            textTransform="capitalize"
                            color={contractStatus[status]}
                          >
                            {text(status)}
                          </MDTypography>
                        </div>
                        <div className="flexTableHead">
                          <MDTypography component="span" type="status" color={isValid ? "inherit" : "error"}>
                            {username}
                          </MDTypography>
                        </div>
                        <div className="flexTableHead">
                          <MDTypography
                            component="span"
                            type="status"
                            textTransform="capitalize"
                            color={isValid ? "inherit" : "error"}
                          >
                            {lastName}
                          </MDTypography>
                        </div>
                        <div className="flexTableHead">
                          <MDTypography
                            component="span"
                            type="status"
                            color={isValid ? "inherit" : "error"}
                            textTransform="capitalize"
                          >
                            {firstName}
                          </MDTypography>
                        </div>
                        <div style={{ position: "absolute", right: "0px", zIndex: "2" }} className="flexTableHead">
                          <MoreVertIcon
                            style={{ width: "22px", transform: "translateY(2px)", height: "22px" }}
                            color={isValid ? "inherit" : "error"}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      key={contractId}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Collapse
                        style={{
                          width: "calc(100% - 20px)",
                        }}
                        in={open[contractId]}
                      >
                        <div
                          key={contractId}
                          style={{
                            marginBottom: "15px",
                            padding: "10px 8px",
                            backgroundColor: "#F2F2F2",
                          }}
                        >
                          <div className="flexTableRows" style={{ border: "none" }}>
                            <div className="flexTableHead">
                              <MDTypography className="global_label" type="tableHeader" textTransform="capitalize">
                                {text("widgets-business-tenants-details-motorists-table-header-contractId")}
                              </MDTypography>
                            </div>
                            <div className="flexTableData">
                              <MDTypography type="tableData">{ospContractId}</MDTypography>
                            </div>
                            <div className="flexTableHead">
                              <MDTypography className="global_label" type="tableHeader" textTransform="capitalize">
                                {text("widgets-business-tenants-details-motorists-table-header-status")}
                              </MDTypography>
                            </div>
                            <div className="flexTableData">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  transform: "translateX(-5px)",
                                }}
                              >
                                <Switch
                                  size="medium"
                                  color="warning"
                                  checked={isActive}
                                  onChange={(event) =>
                                    onContractSwitch({
                                      event: event.target.checked,
                                      contractId,
                                    })
                                  }
                                />
                                <MDTypography type="status" textTransform="first" color={contractStatus[status]}>
                                  {text(status)}
                                </MDTypography>
                              </div>
                            </div>
                          </div>

                          <div className="flexTableRows" style={{ marginTop: "-10px", border: "none" }}>
                            <div className="flexTableHead">
                              <MDTypography className="global_label" type="tableHeader" textTransform="capitalize">
                                {text("widgets-business-tenants-details-motorists-table-header-startDate")}
                              </MDTypography>
                            </div>
                            <div className="flexTableData">
                              {!isEditable ? (
                                <span>
                                  {DateTime.fromISO(startDate).toFormat(
                                    process.env.NODE_ENV === "development" ? "dd.MM.yyyy HH:mm:ss" : "dd.MM.yyyy",
                                  )}
                                </span>
                              ) : (
                                <DatePicker
                                  editable={false}
                                  cleanable={false}
                                  value={
                                    formData?.startDate &&
                                    new Date(formData?.startDate) instanceof Date &&
                                    !isNaN(new Date(formData?.startDate))
                                      ? new Date(formData?.startDate)
                                      : null
                                  }
                                  format="dd.MM.yy HH:mm"
                                  disabled={DateTime.fromISO(startDate) < DateTime.now() ? true : false}
                                  onChange={(date) => {
                                    if (
                                      date &&
                                      formData?.stopDate &&
                                      new Date(formData?.stopDate) <= new Date(formData?.startDate)
                                    ) {
                                      setFormData({
                                        ...formData,
                                        stopDate: isToday(date)
                                          ? addDays(new Date(), 1)
                                          : addDays(new Date(DateTime.fromISO(date.toISOString()).startOf("day")), 1),
                                        startDate: isToday(date)
                                          ? new Date()
                                          : new Date(DateTime.fromISO(date.toISOString()).startOf("day")),
                                      });
                                    } else {
                                      setFormData({
                                        ...formData,
                                        startDate: isToday(date)
                                          ? new Date()
                                          : new Date(DateTime.fromISO(date.toISOString()).startOf("day")),
                                      });
                                    }
                                  }}
                                  ranges={[]}
                                  style={{
                                    width: "calc(100% - 20px)",
                                  }}
                                  shouldDisableDate={allowedRange(
                                    DateTime.now(),
                                    subDays(new Date(subContract.stopDate), 1),
                                  )}
                                  hideHours={() => true}
                                  hideMinutes={() => true}
                                />
                              )}
                            </div>
                            <div className="flexTableHead">
                              <MDTypography className="global_label" type="tableHeader" textTransform="capitalize">
                                {text("widgets-business-tenants-details-motorists-table-header-endDate")}{" "}
                              </MDTypography>
                            </div>
                            <div className="flexTableData">
                              {!isEditable ? (
                                <span>
                                  {DateTime.fromISO(endDate).toFormat(
                                    process.env.NODE_ENV === "development" ? "dd.MM.yyyy HH:mm:ss" : "dd.MM.yyyy",
                                  )}
                                </span>
                              ) : (
                                <DatePicker
                                  value={new Date(formData?.stopDate)}
                                  format="dd.MM.yy HH:mm"
                                  onChange={(date) => {
                                    if (date) {
                                      setFormData({
                                        ...formData,
                                        stopDate: new Date(DateTime.fromISO(date.toISOString()).endOf("day")),
                                      });
                                    }
                                  }}
                                  style={{
                                    width: "calc(100% - 20px)",
                                  }}
                                  hideHours={() => true}
                                  hideMinutes={() => true}
                                  editable={false}
                                  cleanable={false}
                                  shouldDisableDate={combine(
                                    allowedRange(
                                      format(
                                        formData.startDate
                                          ? addDays(new Date(formData.startDate), 1)
                                          : new Date(subContract?.startDate),
                                        "yyyy-MM-dd",
                                      ),
                                      format(subDays(new Date("2099-12-31"), 1), "yyyy-MM-dd"),
                                    ),
                                    beforeToday(),
                                  )}
                                />
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "15px",
                            }}
                          >
                            {isEditable ? (
                              <MDButton onClick={() => handleSaveClick(contractId)} color="primary">
                                {text("wizard-button-save")}
                              </MDButton>
                            ) : (
                              <div>
                                <MDButton
                                  style={{ marginRight: "10px" }}
                                  onClick={() => handleEditClick(startDate, endDate)}
                                  color="primary"
                                >
                                  {text("wizard-button-edit")}
                                </MDButton>
                                {isActive ? (
                                  <MDButton
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => terminateClick(contractId, firstName, lastName)}
                                    color="primary"
                                  >
                                    {text("global-button-terminate")}
                                  </MDButton>
                                ) : null}
                              </div>
                            )}
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                );
              },
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ContractDetailsTable;
