import * as React from "react";
import Popover from "@mui/material/Popover";
import ArticleIcon from "@mui/icons-material/Article";

import MDBox from "assets/@ndres-ui/MDBox";

export default function PopOver(props) {
  const { children } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <MDBox>
      <ArticleIcon
        className="article__icon"
        sx={{ color: "#B4AFAA ", cursor: "pointer" }}
        fontSize="medium"
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      />

      <style jsx="true">{`
        .article__icon {
          transition: all 0.3s ease-in-out;
        }
        .article__icon:hover {
          color: #800080;
        }
      `}</style>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ transform: `translate(-50px, 0px)` }}
      >
        <MDBox bgColor="white" p="0.3em" border="1px solid #B4AFAA">
          {children}
        </MDBox>
      </Popover>
    </MDBox>
  );
}
