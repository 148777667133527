import React from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "@xstate/react";

import { service } from "./reducer";
import { Link } from "react-router-dom";
import {
  NavigationBarContainer,
  MenuItem,
  MenuBox,
  LogoBox,
  MarginedBox,
  MenuItemSearch,
} from "../../DashboardElements/components/layout/NavigationBar/style";
import LanguageSelector from "../../DashboardElements/components/layout/NavigationBar/LanguageSelector";
import LogoSvg from "assets/images/icons/logo_osp.svg";

import useStateCallback from "assets/commonHooks/useStateCallback/useStateCallback";
import image from "assets/images/parkingScene_1.jpg";
import MDBox from "assets/@ndres-ui/MDBox";
import useDictionaries from "assets/commonHooks/useDictionaries";
import MDTypography from "assets/@ndres-ui/MDTypography";

import CardMembershipIcon from "@mui/icons-material/CardMembership";
import RoomIcon from "@mui/icons-material/Room";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import ArticleIcon from "@mui/icons-material/Article";

const Login = () => {
  const text = useDictionaries();
  const dispatch = useDispatch();
  const [form, setForm] = useStateCallback({ email: "", password: "" });

  const connected = useSelector(service, ({ context }) => context.connected);
  const loginError = useSelector(service, ({ context }) => context.error);
  const loading = useSelector(service, ({ value }) => value === "connecting");

  const loginSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: "login:login",
      payload: { username: form.email, password: form.password },
    });
  };

  const handleFormChange = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  if (connected) return <Navigate to="/dashboard" replace={true} />;

  return (
    <MDBox height="100vh" width="100vw">
      <NavigationBarContainer>
        <MarginedBox>
          <Link to={"/dashboard"}>
            <LogoBox>
              <img src={LogoSvg} style={{ marginTop: "0.6em", height: "90%" }} alt="website logo" />
            </LogoBox>
          </Link>
          <MenuBox>
            <MenuItemSearch>{/* <SearchBar /> */}</MenuItemSearch>
            <MenuItem>
              <LanguageSelector />
            </MenuItem>
            <MenuItem></MenuItem>
          </MenuBox>
        </MarginedBox>
      </NavigationBarContainer>
      <MDBox
        height="72vh"
        style={{
          background: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <MDBox pt="17vh" width="30%" mr="5vw" ml="auto">
          <MDBox bgColor="#eceaead6" borderRadius="2px">
            <form
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "row",
                alignItems: "center",
                padding: "2em 0 0 0",
              }}
            >
              <MDBox width="50%">
                <label>
                  <MDTypography type="tableData">{text("landing-login-email")}</MDTypography>
                </label>
                <input
                  label="Email"
                  type="email"
                  id="email"
                  onChange={handleFormChange}
                  value={form.email}
                  style={{ height: "30px", marginBottom: "1em", width: "100%" }}
                />
                <label>
                  <MDTypography type="tableData">{text("landing-login-password")}</MDTypography>
                </label>
                <input
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="on"
                  onChange={handleFormChange}
                  value={form.password}
                  style={{ height: "30px", marginBottom: "1em", width: "100%" }}
                />
              </MDBox>

              <MDBox width="40%" alignSelf="end" mb="1em" textAlign="center">
                <input
                  type="submit"
                  value={text("landing-login-submit")}
                  variant="gradient"
                  onClick={loginSubmit}
                  style={{
                    backgroundColor: "#800080",
                    width: "50%",
                    height: "30px",
                    borderRadius: "2px",
                    color: "white",
                  }}
                ></input>
              </MDBox>
            </form>
            {loginError ? (
              <MDTypography type="status" color="error" textAlign="center">
                {text("landing-login-error")}
              </MDTypography>
            ) : (
              <MDTypography> </MDTypography>
            )}
            <MDBox height="50px" bgColor="#b4afaa" space="row-ar" color="white">
              <MDTypography type="status" color="light" textAlign="center" width="50%" style={{ fontSize: "1.2em" }}>
                {text("landing-login-no-account")}
              </MDTypography>

              <MDBox style={{ width: "40%", textAlign: "center" }}>
                <button style={{ width: "50%", height: "30px", color: "#b4afaa", fontSize: "1em" }}>
                  {text("landing-login-contact-us")}
                </button>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>

        <h1 style={{ color: "white", marginTop: "1.5em", textAlign: "center" }}>{text("landing-login-main-title")}</h1>
        <h2 style={{ color: "white", textAlign: "center" }}>{text("landing-login-sub-title")}</h2>
        <section
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "2em",
            paddingBottom: "300px",
          }}
        >
          <MDBox width="22%" height="100%" minHeight="390px" p="0 2em" space="col" bgColor="#eceaea">
            <RoomIcon
              sx={{
                fontSize: "4em",
                transform: "scale(1.6)",
                color: "#800080",
                marginTop: "1em",
                marginBottom: "0.5em",
              }}
              fontSize="xxxl"
            />
            <MDBox mt="1em">
              <MDTypography type="cardTitle">{text("landing-section-plateform-title-first")}</MDTypography>
              <MDTypography type="cardTitle">{text("landing-section-plateform-title-second")}</MDTypography>
            </MDBox>
            <MDBox mt="1em">
              <MDTypography type="cardPara">{text("landing-section-plateform-paragraph-first")}</MDTypography>
            </MDBox>
            <MDBox mb="2em">
              <MDTypography type="cardPara">{text("landing-section-plateform-paragraph-second")}</MDTypography>
            </MDBox>
          </MDBox>
          <MDBox width="22%" height="100%" minHeight="390px" p="0 2em" space="col" bgColor="#eceaea">
            <LocalParkingIcon
              sx={{
                fontSize: "4em",
                transform: "scale(1.5)",
                color: "#800080",
                marginTop: "1em",
                marginBottom: "0.5em",
                border: "1px solid #800080",
              }}
              fontSize="xxxl"
            />
            <MDBox
              style={{
                width: "100%",
                marginTop: "1em",
              }}
            >
              <MDTypography type="cardTitle">{text("landing-section-cards-title-first")}</MDTypography>
              <MDTypography type="cardTitle">{text("landing-section-cards-title-second")}</MDTypography>
            </MDBox>
            <MDBox mt="1em">
              <MDTypography type="cardPara">{text("landing-section-cards-paragraph-first")}</MDTypography>
            </MDBox>
            <MDBox mb="2em">
              <MDTypography type="cardPara">{text("landing-section-cards-paragraph-second")}</MDTypography>
            </MDBox>
          </MDBox>
          <MDBox width="22%" height="100%" minHeight="390px" p="0 2em" space="col" bgColor="#eceaea">
            <CardMembershipIcon
              sx={{
                fontSize: "4em",
                transform: "scale(1.6)",
                color: "#800080",
                marginTop: "1em",
                marginBottom: "0.5em",
              }}
              fontSize="xxxl"
            />
            <MDBox width="100%" mt="1em">
              <MDTypography type="cardTitle">{text("landing-section-subscriptions-title")}</MDTypography>
              <MDTypography type="cardTitle">{text("landing-section-subscriptions-title-second")}</MDTypography>
            </MDBox>
            <MDBox mt="1em">
              <MDTypography type="cardPara">{text("landing-section-subscriptions-paragraph-first")}</MDTypography>
            </MDBox>
            <MDBox mb="2em">
              <MDTypography type="cardPara">{text("landing-section-subscriptions-paragraph-second")}</MDTypography>
            </MDBox>
          </MDBox>
          <MDBox width="22%" height="100%" minHeight="390px" p="0 2em" space="col" bgColor="#eceaea">
            <ArticleIcon
              sx={{
                fontSize: "4em",
                transform: "scale(1.6)",
                color: "#800080",
                marginTop: "1em",
                marginBottom: "0.5em",
              }}
              fontSize="xxxl"
            />
            <MDBox width="100%" mt="1em">
              <MDTypography type="cardTitle">{text("landing-section-network-title")}</MDTypography>
              <MDTypography type="cardTitle">{text("landing-section-network-title-second")}</MDTypography>
            </MDBox>
            <MDBox mt="1em">
              <MDTypography type="cardPara">{text("landing-section-network-paragraph-first")}</MDTypography>
            </MDBox>
            <MDBox mb="2em">
              <MDTypography type="cardPara">{text("landing-section-network-paragraph-second")}</MDTypography>
            </MDBox>
          </MDBox>
        </section>
      </MDBox>
    </MDBox>
  );
};

export default Login;
