import { gql } from "@apollo/client";

export default gql`
  query session($sessionId: String!) {
    session(sessionId: $sessionId) {
      _id
      state
      startDate
      endDate
      occupancyDuration
      parking {
        _id
        name
        accessPoints {
          _id
          shortCode
        }
      }
      contract {
        _id
        isEnabled
        stopDate
        startDate
        ospContractId
        product {
          _id
        }
        parking {
          name
          _id
        }
      }
      events {
        state
        credentials {
          type
          value
          description
          provider
        }
        accessPointId
        createdAt
        reason
      }
      motorist {
        _id
        firstName
        lastName
        username
        phone
        address1
        address2
        zipcode
        city
        country
        isAnonymous
        state
      }
    }
  }
`;
