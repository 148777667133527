/* eslint-disable implicit-arrow-linebreak */
import React, { useContext, useMemo } from "react";

import { LocalContext } from "../../../context";

import { LocalContext as WidgetContext } from "../../../../context";

import useDictionaries from "assets/commonHooks/useDictionaries";
import Paginat from "assets/@luc-ui/Pagination";
import MotoristDetailsTable from "assets/@luc-ui/BusinessTenantsDetails/MotoristDetailsTable";

const MotoristContractsTable = () => {
  const text = useDictionaries();
  const { poolContract } = useContext(LocalContext);

  const { onSelectMotoristPage, onContractStatusChange, onContractUpdate, onContractPoolUpdate } =
    useContext(WidgetContext);
  const motoristsListFormated = useMemo(() => poolContract, [poolContract, text]);

  const { motoristsContracts, subContract, motoristsPaging } = motoristsListFormated;

  if (!motoristsContracts) return <></>;

  const { page, total } = motoristsPaging;

  return (
    <>
      <div
        style={{
          position: "relative",
          padding: "0.1em",
        }}
      >
        <MotoristDetailsTable
          onContractSwitch={onContractStatusChange}
          motoristContracts={motoristsContracts}
          onContractUpdate={onContractUpdate}
          onContractPoolUpdate={onContractPoolUpdate}
          subContract={subContract}
          motoristPaging={motoristsPaging}
          onSelectMotoristPage={onSelectMotoristPage}
          isParent={false}
        />
        <Paginat page={page} totalPages={total} onSelectPage={onSelectMotoristPage} />
      </div>
    </>
  );
};
export default MotoristContractsTable;
