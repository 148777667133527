import { DateTime } from "luxon";
import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import createSubContractGQL from "./createSubContract.gql";

const createContract = async (
  {
    motoristInputs: { contractId, newContractSpacesAllocated, ospContractId, contractName, startDate, stopDate },
    token,
  },
  { payload },
) =>
  new Promise(async (resolve, reject) => {
    client
      .mutate({
        mutation: createSubContractGQL,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          input: {
            contractId,
            newContractSpacesAllocated,
            ospContractId,
            contractName,
            startDate,
            stopDate,
          },
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

const defaultContract = {
  name1: "",
  startDate: null,
  stopDate: null,
  numberOfSpaces: 2,
  linkedContractId: "",
};

const userModel = createModel({
  token: "",
  showWizard: false,
  selectedParking: undefined,
  linkedContractId: "",
  parkingId: undefined,
  productId: undefined,
  contractId: undefined,
  product: undefined,
  motoristInputs: {
    loading: false,
    error: false,
    success: false,
    ...defaultContract,
    contractStatus: true,
  },
  currentIndex: 0,
  finish: false,
});

const Wizard = createMachine(
  {
    context: userModel.initialContext,
    id: "WizardCreateSubContractPool",
    initial: "off",
    states: {
      off: {
        on: {
          "WizardCreateSubContractPool:wakeup": { target: "loading" },
        },
      },
      idle: {
        on: {
          "WizardCreateSubContractPool:wakeup": { target: "loading" },

          "motorist:change": {
            actions: ["update:motoristInputs"],
          },
          "WizardCreateSubContractPool:sleep": {
            actions: "hide wizard",
            target: "off",
          },
          "WizardCreateSubContractPool:reset": {
            actions: "createContractReset",
          },
          "WizardCreateSubContractPool:createContract": {
            target: "createContract",
          },
        },
      },
      loading: {
        entry: ["reload data model", "save parking"],
        always: {
          target: "idle",
        },
      },
      createContract: {
        entry: "save token",
        invoke: {
          id: "createContract",
          src: createContract,
          onDone: [{ actions: "populateCreateContract", target: "idle" }],
          onError: [{ actions: "populateCreateContractError", target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populateCreateContract: assign({
        showWizard: (ctx, { payload }) => false,
        error: (_, { data }) => false,
        state: () => "validated",
        errorMessage: (_, { data }) => "",
        lastCreatedContracts: (_, { data }) => data._id,
      }),
      populateCreateContractError: assign({
        error: (_, { data }) => true,
        errorMessage: (_, { data }) => {
          return data;
        },
      }),
      "hide wizard": assign({
        queryStatus: (ctx, { payload }) => "idle",
        showWizard: (ctx, { payload }) => false,
        parkingId: (ctx, { payload }) => undefined,
        productId: (ctx, { payload }) => undefined,
        contractId: (ctx, { payload }) => undefined,
      }),
      "reload data model": assign({
        showWizard: (ctx, { payload }) => true,
        token: () => localStorage.getItem("token"),
        contractId: (ctx, { payload }) => payload.contractId,
        linkedContractId: (ctx, { payload }) => payload.contractId,
        motoristInputs: (ctx, { payload }) => ({
          contractId: payload.contractId,
          tenantContracts: payload.tenantContracts,
          contractStatus: payload.contractStatus,
          spacesAllocated: payload.spacesAllocated,
          newContractSpacesAllocated: 2,
          spacesOccupied: payload.spacesOccupied,
          name1: payload.name1,
          ospContractId: payload.ospContractId,
          contractName: "",
          startDate: null,
          error: false,
        }),
        queryStatus: (ctx, { payload }) => undefined,
        finish: () => false,
        currentIndex: () => 0,
      }),
      createContractReset: assign({
        error: (_, { data }) => null,
        loading: (_, { data }) => false,
        success: (_, { data }) => false,
        externalId: (_, { data }) => null,
        queryStatus: (ctx, { payload }) => undefined,
        finish: () => false,
        currentIndex: () => 0,
        motoristInputs: ({ motoristInputs }, { data }) => ({
          ...motoristInputs,
          startDate: null,
          stopDate: null,
          contractName: "",
          newContractSpacesAllocated: 2,
        }),
      }),
      "update:motoristInputs": assign({
        motoristInputs: (ctx, { payload }) => ({
          ...ctx.motoristInputs,
          ...payload,
        }),
      }),
      setLoading: assign({
        loading: (_, { data }) => true,
        motoristInputs: (ctx) => ({
          ...ctx.motoristInputs,
        }),
      }),
      "save parking": assign({
        selectedParking: (ctx, { payload }) => payload.selectedParking,
      }),
      "save token": assign({
        token: () => localStorage.getItem("token"),
      }),
    },
  },
);

export const service = interpret(Wizard).start();

const widgetReducer = (state = service.initialState, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
