import React, { useState } from "react";
import { useSelector } from "@xstate/react";

import { ContextProvider } from "./context";
import { service } from "../LastIncidents/reducer";

import NavigationTabs from "components/MotoristDetails/NavigationTabs";
import MotoristAboutTable from "components/MotoristDetails/About/materials/table";
import MotoristContractsTable from "components/MotoristDetails/Contracts/materials/table";
import Sessions from "components/MotoristDetails/Sessions/materials/table";
import useDictionaries from "assets/commonHooks/useDictionaries";

const MotoristDetails = (props) => {
  // Handle to show Sessions tab if we are from a click on an incident
  const fromIncidents = useSelector(service, ({ context }) => !!context.selectedFilterMotorist);
  const onSelectSessionPage = (sessionPage) => ({ sessionPage });
  const onSelectContractPage = (contractPage) => ({ contractPage });
  const onDownloadPdf = (id) => ({ id });
  const onRegenerateQrcode = (id) => ({ id });

  const onForceClose = (sessionId) => ({ sessionId });

  const [tap, setTab] = useState(fromIncidents ? 2 : 0);
  const text = useDictionaries();

  const onContractStatusChange = (payload) => payload;
  const onSendMail = (payload) => {
    return payload;
  };
  const onFreeFlag = (payload) => {
    return payload;
  };

  const displayAbout = tap !== 0 ? "none" : "block";
  const displayContract = tap !== 1 ? "none" : "block";
  const displaySessions = tap !== 2 ? "none" : "block";

  return (
    <div
      style={{
        height: "100%",
        overflow: "hidden",
        position: "relative",
        padding: "0",
      }}
    >
      <div className="navTab" style={{ position: "fixed", width: "100%", background: "white" }}>
        <NavigationTabs
          tap={tap}
          onChange={(tab) => setTab(tab)}
          ml={2}
          mr={2}
          optionsTabs={[
            text("widgets-motorist-information-navigation-about"),
            text("widgets-motorist-information-navigation-contracts"),
            text("widgets-motorist-information-navigation-sessions"),
          ]}
        />
      </div>
      <div style={{ paddingTop: "5em" }}>
        <ContextProvider
          storeName="MotoristDetails"
          events={{
            onContractStatusChange,
            onSendMail,
            onSelectContractPage,
            onSelectSessionPage,
            onForceClose,
            onDownloadPdf,
            onRegenerateQrcode,
            onFreeFlag,
          }}
        >
          <div style={{ display: `${displayAbout}` }}>
            <MotoristAboutTable />
          </div>
          <div style={{ display: `${displayContract}` }}>
            <MotoristContractsTable />
          </div>
          <div style={{ display: `${displaySessions}` }}>
            <Sessions />
          </div>
        </ContextProvider>
      </div>
    </div>
  );
};

export default MotoristDetails;
