import { gql } from "@apollo/client";

export default gql`
  query verifyUser($token: String!) {
    verifyUser(token: $token) {
      user {
        _id
        username
        firstName
        lastName
        phone
        lang
      }
      fieldsToVerify
    }
  }
`;
